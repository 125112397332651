module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"render-blocking","custom":[{"name":["Vollkorn","Cormorant"],"file":"/fonts/typography-local.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":86597353,"webvisor":true,"trackHash":true,"afterBody":false,"defer":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Landshaft","short_name":"Landshaft","start_url":"/","background_color":"#fbfbfb","theme_color":"#fbfbfb","display":"standalone","icons":[{"src":"./favicons/android-chrome-192x192.png?v=1.1","sizes":"192x192","type":"image/png"},{"src":"./favicons//android-chrome-256x256.png?v=1.1","sizes":"256x256","type":"image/png"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
